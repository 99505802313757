<template>
  <div class="mainPage">
    <div class="mainPageContent">
      <div class="contentPanel">
        <div class="headerLogo">
          <img
            src="../../assets/logo_b.png"
            alt="Osmose"
            @click="openDashboard()"
          />
        </div>

        <div class="formList">
            <h6 class="poleId">
                <strong>Pole ID: </strong>{{
              damageData.poleData !== undefined
                ? damageData.poleData.poleId
                : ""
                }}
            </h6>
            <div class="textCenter info1 fnt24">Add Damage Detail</div>
            <div class="damageCard repeatCard">
                <div class="Box1">
                    <div class="listBox">
                        <img v-if="damage.TYPE == 'ExposedPocket'"
                             src="../../assets/arc1.png"
                             alt="Exposed Pocket"
                             :style="`transform: rotate(${damage.ORIENT * -1}deg);`" />
                        <img v-else-if="damage.TYPE == 'EnclosedPocket'"
                             src="../../assets/arc2.png"
                             alt="Enclosed Pocket"
                             :style="`transform: rotate(${damage.ORIENT * -1}deg);`" />
                        <img v-else
                             src="../../assets/arc4.png"
                             alt="Mechanical Damage"
                             :style="`transform: rotate(${damage.ORIENT * -1}deg);`" />
                        <div class="boxTitle">{{ title }}</div>
                    </div>
                </div>
                <div class="damageDet">
                    <div v-if="!orientPopup" class="formGroup mr10">
                        <label>Orientation: </label>
                        <button class="flex-1 btnblue" @click="selectOrientation">
                            {{ orientation }}
                        </button>
                    </div>
                    <div class="selectDrop" v-else>
                        <div class="closeselectDrop" v-on:click="closeOrientation()">
                            CANCEL
                        </div>
                        <div class="oDesign">
                            <ul id="cont" class="circle-container lol">
                                <li>
                                    <button type="button" @click="loLClick">
                                        <span>LOL</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="_45Click">
                                        <span>-45&#176;</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="_90Click">
                                        <span>-90&#176;</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="_135Click">
                                        <span>-135&#176;</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="_loLClick">
                                        <span>-LOL</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="btn135Click">
                                        <span>135&#176;</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="btn90Click">
                                        <span>90&#176;</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" @click="btn45Click">
                                        <span>45&#176;</span>
                                    </button>
                                </li>
                            </ul>
                            <div class="arrowMain">
                                <span class="arrow-right"><span>Line of Lead</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="formGroup">
                        <label>Arc Length (inches): </label>
                        <input title=""
                               type="number"
                               min="0"
                               v-bind:readonly="readOnly"
                               v-model="damage.ARCLEN"
                               :class="[
                    arcErr ? 'errormsgBorder' : '',
                    'formControl',
                    'spinNone',
                  ]"
                               required
                               @keypress="decimalPoint($event, damage.ARCLEN)"
                               @paste="decimalPoint($event, damage.ARCLEN)"
                               placeholder="Enter Value" />
                    </div>
                    <div v-if="
                  damageData.type !== 'ExposedPocket' &&
                  damageData.type !== 'Mechanical'
                "
                         class="formGroup mr10">
                        <label>Shell (inches): </label>
                        <input title=""
                               type="number"
                               min="0"
                               v-bind:readonly="readOnly"
                               v-model="damage.SHELL"
                               :class="[
                    shellErr ? 'errormsgBorder' : '',
                    'formControl',
                    'spinNone',
                  ]"
                               required
                               @keypress="decimalPoint($event, damage.SHELL)"
                               @paste="decimalPoint($event, damage.SHELL)"
                               placeholder="Enter Value" />
                    </div>
                    <div v-if="damageData.type !== 'Mechanical'" class="formGroup">
                        <label>Depth (inches): </label>
                        <input title=""
                               type="number"
                               min="0"
                               v-bind:readonly="readOnly"
                               v-model="damage.DEPTH"
                               :class="[
                    depthErr ? 'errormsgBorder' : '',
                    'formControl',
                    'spinNone',
                  ]"
                               required
                               @keypress="decimalPoint($event, damage.DEPTH)"
                               @paste="decimalPoint($event, damage.DEPTH)"
                               placeholder="Enter Value" />
                    </div>
                    <div v-if="!formValid" class="error">{{ errMsg }}</div>
                </div>
            </div>
            <button class="btnblue" v-if="allowEditting" @click="onSave">SAVE</button>
            <button class="btnblue" v-if="allowEditting==false" @click="onDone">DONE</button>
            <div class="textCenter" v-if="allowEditting" @click="onCancel">
                <a href="javascript: void(0);" class="backLink txtUppercase">
                    CANCEL
                </a>
            </div>
        </div>
      </div>
      <shared-footer></shared-footer>
      <div class="popUp" v-if="popup">
        <div class="popUpBox">
          <p class="popupText">Changes will not be saved. Continue?</p>
          <div class="modalFooter">
            <button class="btnRounded" v-on:click="closePrompt()">
              CANCEL
            </button>
            &nbsp;&nbsp;
            <button class="btnRoundedFull" v-on:click="confirmPrompt()">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleIDB from "../../api/indexedDBService.js";
import SharedFooter from "../SharedFooter.vue";
import { v4 as uuidv4 } from "uuid";
import { store } from "../../store/index.js";
import { mapGetters } from "vuex";
import Eruda from 'eruda';


export default {
  name: "DamageDetail",

  data: () => ({
    title: "",
    damageData: {},
    damage: {
      ID: "",
      TYPE: "",
      ORIENT: "Select",
      ARCLEN: "",
      SHELL: "",
      DEPTH: "",
      SOURCE: "Manual",
    },
    radius: "",
    formValid: false,
    orientPopup: false,
    popup: false,
    errMsg: "",
    arcErr: false,
    shellErr: false,
    depthErr: false,
      orientation: "LoL",
      allowEditting: true,
      readOnly: false,
      fromFulcrum:false
  }),

  components: {
    "shared-footer": SharedFooter,
  },

  computed: {
    ...mapGetters(["getDamageRecord"]),
  },

        mounted() {
    this.damageData = JSON.parse(this.getDamageRecord);
            
    this.radius =
      parseFloat(this.damageData.poleData.origCircumference) / 3.14 / 2;

    if (this.damageData.type === "ExposedPocket") {
      this.title = "Exposed Pocket";
      this.damage.TYPE = this.damageData.type;
      this.damage.ORIENT = 0;
    }

    if (this.damageData.type === "EnclosedPocket") {
      this.title = "Enclosed Pocket";
      this.damage.ORIENT = 0;
      this.damage.ARCLEN =
        Math.round(
          (parseFloat(this.damageData.poleData.origCircumference) / 4) * 2
        ) / 2;
      this.damage.DEPTH = Math.round(this.radius * 0.8 * 2) / 2;
      this.damage.TYPE = this.damageData.type;
    }

    if (this.damageData.type === "Mechanical") {
      this.title = "Mechanical Damage";
      this.damage.ORIENT = 0;
      this.damage.TYPE = this.damageData.type;
    }

    if (this.damageData.damage) {
      if (this.damageData.damage.ORIENT == 0) this.orientation = "LoL";
      else if (this.damageData.damage.ORIENT == 180) this.orientation = "-LoL";
      else this.orientation = this.damageData.damage.ORIENT;
      this.damage.ORIENT = this.damageData.damage.ORIENT;
      this.damage.ARCLEN = this.damageData.damage.ARCLEN;
      this.damage.SHELL = this.damageData.damage.SHELL;
      this.damage.DEPTH = this.damageData.damage.DEPTH;
    }
  },
        created() {

            if (localStorage.getItem("ErudaEnabled") === "Yes")
                Eruda.init();
            this.standalone = !store.getters.fromFulcrum;
            this.fromFulcrum = store.getters.fromFulcrum;
            //debugger;
            if (this.fromFulcrum) {
                this.allowEditting = (localStorage.getItem("readOnly") == "false");
                this.readOnly = !this.allowEditting;
            }
        },

  methods: {
    openDashboard() {
      this.$router.replace("/dashboard");
    },

    closeOrientation() {
      this.orientPopup = false;
    },

      selectOrientation() {
          if (!this.readOnly)
            this.orientPopup = true;
    },

    closePrompt() {
      this.popup = false;
    },

    confirmPrompt() {
      this.popup = true;
      this.$router.go(-1);
    },

    loLClick() {
      this.damage.ORIENT = 0;
      this.orientation = "LoL";
      this.closeOrientation();
    },

    _loLClick() {
      this.damage.ORIENT = 180;
      this.orientation = "-LoL";
      this.closeOrientation();
    },

    _45Click() {
      this.damage.ORIENT = this.orientation = -45;
      this.closeOrientation();
    },

    btn45Click() {
      this.damage.ORIENT = this.orientation = 45;
      this.closeOrientation();
    },

    btn90Click() {
      this.damage.ORIENT = this.orientation = 90;
      this.closeOrientation();
    },

    _90Click() {
      this.damage.ORIENT = this.orientation = -90;
      this.closeOrientation();
    },

    btn135Click() {
      this.damage.ORIENT = this.orientation = 135;
      this.closeOrientation();
    },

    _135Click() {
      this.damage.ORIENT = this.orientation = -135;
      this.closeOrientation();
    },

    onSave() {
        this.validateDamage();

      },
      onDone() {
          this.backToEvaluation();

      },

    validateDamage() {
      this.validateOrient();
      if (this.formValid) {
        this.validateArcLen();
        if (this.formValid) {
          this.validateShell();
          if (this.formValid) {
            this.validateDepth();
            if (this.formValid) {
              let damageKey = uuidv4();
              //let damageRecord = {
              //  damageID: damageKey,
              //  type: this.damageData.type,
              //};
              if (!this.damageData.damage) {
                  this.damage.ID = damageKey;
              } else {
                  this.damage.ID = this.damageData.damage.ID;
                  for (let i = 0; i < this.damageData.poleData.damages.length; i++)
                      if (this.damageData.poleData.damages[i].ID == this.damage.ID)
                      {
                          this.damageData.poleData.damages.splice(i,1);
                          break;
                      }
              }
                this.damageData.poleData.damages.push(this.damage);
                this.damage.UPDATEDON = new Date();
                if (store.getters.fromFulcrum) {
                    this.backToEvaluation();
                }
                else
                {
                    this.saveDamage(damageKey, JSON.stringify(this.damage));
                }
            }
          }
        }
      }
    },

    validateOrient() {
      if (this.damage.ORIENT === "Select") {
        this.errMsg = "Select orientation!";
        this.formValid = false;
      } else this.formValid = true;
    },

    validateArcLen() {
      if (!this.damage.ARCLEN) {
        this.errMsg = "ArcLen empty!";
        this.arcErr = true;
        this.formValid = false;
      } else {
        this.damage.ARCLEN = Math.round(parseFloat(this.damage.ARCLEN) * 2) / 2;
        let arcLenRange =
          parseFloat(this.damageData.poleData.origCircumference) / 2;
        if (this.damage.ARCLEN < 0.5 || this.damage.ARCLEN > arcLenRange) {
          this.errMsg = "ArcLen out of range!";
          this.arcErr = true;
          this.formValid = false;
        } else {
          this.arcErr = false;
          this.formValid = true;
        }
      }
    },

    validateShell() {
      if (
        this.damageData.type === "ExposedPocket" ||
        this.damageData.type === "Mechanical"
      ) {
        this.formValid = true;
      } else {
        if (!this.damage.SHELL) {
          this.errMsg = "Shell empty!";
          this.shellErr = true;
          this.formValid = false;
        } else {
          this.damage.SHELL = Math.round(parseFloat(this.damage.SHELL) * 2) / 2;
          if (this.damage.SHELL < 0.5 || this.damage.SHELL > this.radius) {
            this.errMsg = "Shell out of range!";
            this.shellErr = true;
            this.formValid = false;
          } else {
            this.shellErr = false;
            this.formValid = true;
          }
        }
      }
    },

    validateDepth() {
      if (this.damageData.type === "Mechanical") {
        this.formValid = true;
      } else {
        if (!this.damage.DEPTH) {
          this.errMsg = "Depth empty!";
          this.depthErr = true;
          this.formValid = false;
        } else {
          this.damage.DEPTH = Math.round(parseFloat(this.damage.DEPTH) * 2) / 2;
          if (
            this.damage.DEPTH < parseFloat(this.damage.SHELL) + 0.5 ||
            this.damage.DEPTH > this.radius
          ) {
            this.errMsg = "Depth out of range!";
            this.depthErr = true;
            this.formValid = false;
          } else {
            this.depthErr = false;
            this.formValid = true;
          }
        }
      }
    },

    saveDamage(key, val) {
      let self = this;
        if (this.damageData.damage) key = this.damageData.damage.ID;
        //debugger;
      this.insertObject("damage-data", "SASC_DB", key, val)
        .then(() => {
          self.backToEvaluation();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    backToEvaluation() {
      store.dispatch("setPoleRecord", JSON.stringify(this.damageData.poleData));
      this.$router.replace("/evaluation");
    },

    insertObject(store, db_name, key, val) {
      try {
        let jsonVal = val.includes("{") ? JSON.parse(val) : val;
        return SimpleIDB.SimpleIDB.set(key, jsonVal, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },

    decimalPoint($event, val) {
      try {
        let newVal = $event.clipboardData.getData("text");
        if (newVal) {
          if ((parseFloat(newVal) * 100) % 1 > 0) {
            $event.preventDefault();
            return false;
          }
        }
      } catch {
        if (
          val != null &&
          val.toString().indexOf(".") > -1 &&
          val.toString().split(".")[1].length > 1
        ) {
          $event.preventDefault();
          return false;
        }
      }
    },

    onCancel() {
      if (
        this.damage.ARCLEN !== "" ||
        this.damage.SHELL !== "" ||
        this.damage.DEPTH !== ""
      )
        this.popup = true;
      else this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.oDesign {
  position: relative;
  margin: 50px 0px 40px 0px;
}
.arrowMain {
  width: 72px;
  height: 32px;
  background: #fff;
  border-radius: 6px 0px 0px 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -50%);
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #fff;
  float: right;
  margin-top: -9px;
  margin-right: -17px;
  border-radius: 5px 0px 0px 5px;
  span {
    position: absolute;
    width: 100%;
    font-size: 10px;
    font-weight: 800;
    color: #000;
    top: 10px;
    left: 5px;
  }
}
.spinNone::-webkit-inner-spin-button,
.spinNone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.popupText {
  padding: 20px 20px;
}
.modalFooter {
  border-top: 1px solid #d5d5d5;
  padding: 10px 10px;
}
.btnRounded {
  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  padding: 6px;
  border-radius: 50px;
  color: #2c95ff;
  min-width: 90px;
  cursor: pointer;
}
.btnRoundedFull {
  background: #2c95ff;
  border: 2px solid #2c95ff;
  padding: 6px;
  border-radius: 50px;
  color: #fff;
  min-width: 90px;
  cursor: pointer;
}
.circle-container {
  position: relative;
  width: 10em;
  height: 10em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  margin: auto;
  border: solid 0px #4797ff;
  button {
    height: 40px;
    width: 40px;
    padding: 0px;
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    border: 0px;
  }
}

.circle-container > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  margin: -1.5em;
}

.circle-container > *:nth-of-type(1) {
  transform: rotate(0deg) translate(5em) rotate(0deg);
}
.circle-container > *:nth-of-type(2) {
  transform: rotate(45deg) translate(5em) rotate(0deg);
  button {
    span {
      transform: rotate(-45deg);
      display: inline-block;
    }
  }
}
.circle-container > *:nth-of-type(3) {
  transform: rotate(90deg) translate(5em) rotate(-90deg);
}
.circle-container > *:nth-of-type(4) {
  transform: rotate(135deg) translate(5em) rotate(-90deg);
  button {
    span {
      transform: rotate(-45deg);
      display: inline-block;
    }
  }
}
.circle-container > *:nth-of-type(5) {
  transform: rotate(180deg) translate(5em) rotate(-180deg);
}
.circle-container > *:nth-of-type(6) {
  transform: rotate(225deg) translate(5em) rotate(-180deg);
  button {
    span {
      transform: rotate(-45deg);
      display: inline-block;
    }
  }
}
.circle-container > *:nth-of-type(7) {
  transform: rotate(270deg) translate(5em) rotate(-270deg);
}
.circle-container > *:nth-of-type(8) {
  transform: rotate(315deg) translate(5em) rotate(-280deg);
  button {
    span {
      transform: rotate(-35deg);
      display: inline-block;
    }
  }
}

.selectDrop {
  background: #003468;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 10px;
  position: relative;
  min-height: 80px;

  height: auto;
  transition-property: all;
  transition-duration: 1s;
  .closeselectDrop {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }
}
.delRecord {
  position: absolute;
  right: 0px;
  text-decoration: none;
  top: 10px;
  display: block;
  z-index: 1;
  img {
    width: 20px;
  }
}
.posRel {
  position: relative;
}
.formList {
  position: relative;
  padding-bottom: 20px;
  .formControl {
    width: 82%;
  }
}
.formAbsAuto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.errormsgBorder {
  border: 1px solid #ff0000 !important;
}
.popupText {
  padding: 20px 20px;
}
.modalFooter {
  border-top: 1px solid #d5d5d5;
  padding: 10px 10px;
}
.popUp {
  text-align: center;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.popUpBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #d2e4ff;
  width: 230px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mainPage {
  height: calc(100vh - 50px);
}
.delRecord {
  color: #ff0000;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
}
.headerLogo {
  img {
    width: 230px;
  }
}
.poleId {
  font-size: 16px;
  font-weight: normal;
  margin: 10px 0px;
  text-align: center;
}
.mainPageContent {
  margin: auto;
}
.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 1rem 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}

.title1 {
  margin: 3% 0px;
  text-align: center;
  color: #003468;
  font-weight: 600;
  font-size: 20px;
}
.btndef {
  background: #d5d5d5;
  border-radius: 8px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: none;
}
.btnblue {
  background: #003468;
  border-radius: 8px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: all !important;
}
.error {
  color: #ff0000;
  font-size: 12px;
}
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 1;
  .listInline a {
    color: #003468;
  }
}
.backLink {
  color: #003468;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}
.contentPanel {
  width: 350px;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.info1 {
  color: #003468;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px;
}
.fnt24 {
  font-size: 24px;
}
.ml-1 {
  margin-left: 16px;
}
.internetOffline {
  position: fixed;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  background: #000;
  margin: auto;
  color: #fff;
  font-size: 14px;
  padding: 10px;
}
.offlineMsg {
  width: 300px;
  display: flex;
  align-items: center;
  margin: auto;
  text-align: left;
  line-height: 16px;
  img {
    height: 22px;
    opacity: 0.8;
  }
}
.damageDet {
  label {
    flex: 100%;
  }
  .formGroup {
    align-items: center;
    display: flex;
  }
}
.damageCard {
  border-top: 1px solid #d6d6d6;
  padding: 5px 0px;
  margin: 18px 0px 0px 0px;
  position: relative;
}
.Box1 {
  padding: 15px 0px;
  cursor: pointer;
}
.listBox {
  margin: auto;
  color: #003468;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.boxTitle {
  margin-left: 20px;
}
.flex-1 {
  flex: 25%;
  margin: 0px;
  padding: 12px;
}
//Tabs Only
@media (min-width: 768px) {
  .contentPanel {
    padding-top: 5rem;
  }
  .info1 {
    margin: 4rem 0px;
  }
  .listInline {
    margin: 2rem 0px;
  }
  //   .headerLogo{
  //   img{
  //     width: 230px;
  //     height: 48px;
  //   }
  // }
}
//Desktop only
@media (min-width: 1200px) {
  .listInline {
    margin: 1rem 0px;
  }
  .contentPanel {
    padding-top: 1rem;
  }
  .info1 {
    margin: 1rem 0px;
  }
  .title1 {
    margin: 3% 0px;
  }
}

//Small mobile
@media (max-width: 374.5px) {
  .contentPanel {
    width: 90% !important;
  }
}
</style>
